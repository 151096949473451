<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="indexLayoutContent" style="padding: 0px">
          <div class="page flex-col">
            <div :class="`bardiv ${barclass}`">
              <div class="barlist">
                <template v-for="(item, index) in blinks">
                  <a class="bla" :key="index">
                    <div
                      class="blitem"
                      :key="index"
                      @click="
                        () => {
                          goDiv(item.class, item.url);
                        }
                      "
                    >
                      <span>{{ item.title }}</span>

                      <div class="lined">
                        <img
                          class="line"
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/line.png"
                        />
                      </div>
                    </div>
                  </a>
                </template>

                <div class="blitem" @click="closeBardiv">
                  <img src="../assets/imgs/icon-close.png" />
                </div>
              </div>

              <div class="bltouch" @click="closeBardiv"></div>
            </div>

            <!-- <div class="group_1 flex-col">
              <div class="group_3 flex-row">
                <div class="image-wrapper_1 flex-col justify-between w-logo">
                  <img
                    class="w-logo-img"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/logo.png"
                  />
                </div>

                <div class="blinks justify-end">
                  <template v-for="(item, index) in blinks">
                    <span
                      @mouseover="
                        () => {
                          blinkshandleHover(index);
                        }
                      "
                      v-if="bselectindex != index"
                      class="item flex-col justify-between"
                      :style="index == 5 ? `width:84px` : ``"
                      :key="index"
                    >
                      <span class="itemtext">{{ item.title }}</span>
                    </span>
                    <span
                      @mouseleave="blinkshandleLeave"
                      @click="
                        () => {
                          goDiv(item.class, item.url);
                        }
                      "
                      v-if="bselectindex == index"
                      class="itemf flex-col justify-between"
                      :style="index == 5 ? `width:84px` : ``"
                      :key="index"
                    >
                      <span class="itemtext">{{ item.title }}</span>

                      <div
                        class="lined"
                        :style="index == 5 ? `width:84px` : ``"
                      >
                        <img
                          class="line"
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/line.png"
                        />
                      </div>
                    </span>
                  </template>
                </div>

                <div
                  class="showbar"
                  @click="openBardiv"
                  v-if="barclass == 'closethis'"
                ></div>

                <div class="darkback" :style="{ opacity: topbarOpacity }"></div>
              </div>

              <div class="group_2 flex-row">
                <span class="text_1">Creativity</span>
                <span class="text_2">inspiration-driven</span>
              </div>
            </div> -->

            <!-- <div class="group_5 flex-row justify-between jianjie">
              <div class="jjdiv flex-col justify-between">
                <div class="jjd-0 flex-row">
                  <span class="jjd-0-0">“</span>
                  <span class="jj-title" v-html="basedata.mtitle"></span>
                </div>
                <div class="jjd-1">
                  <span class="paragraph_1" v-html="basedata.mcontent"> </span>
                </div>
              </div>
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="../assets/imgs/icon0.png"
              />
            </div> -->

            <!-- <span class="text_12 huoban">合作伙伴</span>

            <div class="hblist flex-col">
              <div class="image-wrapper_8 flex-row">
                <template v-for="(item, index) in frienddata">
                  <div class="litem" :key="index">
                    <a target="_blank" :href="item.url" v-if="item.url!=''" class="limga">
                    <img
                      class="limg"
                      referrerpolicy="no-referrer"
                      :src="baseUrl + item.imgurl"
                    />
                    </a>
                    <a v-else class="limga">
                    <img
                      class="limg"
                      referrerpolicy="no-referrer"
                      :src="baseUrl + item.imgurl"
                    />
                    </a>
                  </div>
                </template>

              </div>
            </div> -->

            <!-- <div class="sections flex-row justify-between fuwuneirong">
              <template v-for="(item, index) in sections">
                <div
                  :class="`section_div flex-col`"
                  :key="index"
                  @mouseover="
                    () => {
                      sectionhandleHover(index);
                    }
                  "
                >
                  <div class="section_back">
                    <div v-if="sectionindex == index" class="section_red_back">
                      <div class="section_red_back_in"></div>
                    </div>
                    <div class="section_logo flex-row">
                      <img
                        class="i-logo"
                        referrerpolicy="no-referrer"
                        :src="`https://cdn.moheweb.com/vdb/gweb/imgs/${item.img}`"
                      />
                    </div>
                    <div class="section_title flex-row">
                      <span
                        class="section_title_text"
                        v-html="item.title"
                      ></span>
                      <div class="section_line"></div>
                    </div>

                    <div class="section_text_0" v-html="item.desc"></div>

                    <template v-for="(item2, index2) in item.tip">
                      <div
                        class="section_text_1 flex-row justify-start"
                        :key="index2"
                      >
                        <span class="section_text_1_icon">●&nbsp;</span>
                        <span class="section_text_1_text" v-html="item2"></span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div> -->

            <span class="text_51 anli">项目案例</span>
            <div class="group_10 flex-row justify-between">
              <div class="mkdiv flex-row justify-between">
                <div
                  :class="`mkitem ${
                    thisclass2 == '' ? 'selected' : ''
                  } flex-col`"
                  style="padding-left: 3rem; padding-right: 3rem"
                  @click="
                    () => {
                      selectclass2('');
                    }
                  "
                >
                  <span class="mktext">全部</span>
                </div>

                <template v-for="(item, index) in mkinds">
                  <div
                    :class="`mkitem flex-col  ${
                      thisclass2 == item.id ? 'selected' : ''
                    }`"
                    @click="
                      () => {
                        selectclass2(item.id);
                      }
                    "
                    :key="index"
                  >
                    <span class="mktext" v-html="item.name"></span>
                  </div>
                </template>
              </div>
              <!-- <img
                class="image_21"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngeda3e001b1ab1f2ff8ccdbb40d2fa36bc422f9eaebe86adbeab1f91224c04650"
              /> -->

              <div class="images_more_list">
                <div class="imlitem imli0"></div>
                <div class="imlitem imli1"></div>
                <div class="imlitem imli2"></div>
                <div class="imlitem imli3"></div>
              </div>
            </div>

            <div class="pplist flex-row justify-between">
              <svg
                v-if="dataloading"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="8rem"
                height="10rem"
                style="
                  width: 8rem;
                  height: 10rem;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -5rem;
                  margin-left: -4rem;
                  enable-background: new 0 0 50 50;
                "
                viewBox="0 0 24 30"
                xml:space="preserve"
              >
                <rect x="0" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="10" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="20" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </svg>

              <template v-if="data.length > 0">
                <template v-for="(item, index) in data">
                  <div
                    :class="`ppitem ${ppindex == index ? 'ppselected' : ''}`"
                    :style="`animation-delay: ${index * 0.1}s;`"
                    :key="index"
                    @mouseover="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @mouseleave="pphandleLeave"
                  >
                    <img
                      :class="`ppimg ${ppindex == index ? 'ppimgshow' : ''}`"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    />

                    <div
                      class="block_6 flex-col"
                      v-if="ppindex == index"
                      @click="
                        () => {
                          if (item.url == '') {
                            swSlideTo(item.swiperindex);
                          } else {
                            gowebsite(item.url);
                          }
                        }
                      "
                    >
                      <div class="ppinfo">
                        <span class="text_57" v-html="item.pname"></span>
                        <div class="block_7 flex-row justify-start">
                          <template
                            v-for="(item2, index2) in item.keywords.split(',')"
                          >
                            <div
                              class="text-wrapper_23 flex-col"
                              v-if="item2 != ``"
                              :key="index2"
                            >
                              <span class="text_58" v-html="item2"></span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="nopplistshow">
                <div class="nopplist">- NONE -</div>
              </template>
            </div>

            <a class="ahover">
              <div class="ppmore flex-col">
                <span class="text">更多案例</span>
              </div>
            </a>

            <div class="group_12 flex-col lianxiwomen">
              <div class="box_4 flex-row justify-between">
                <div class="group_13 flex-col align-center">
                  <div class="fw flex-col">
                    <img
                      class="fwf"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/fw2.png"
                    />
                  </div>
                </div>
                <div class="group_14 flex-col justify-between">
                  <div class="logo-red flex-row">
                    <img
                      class="logo-red-img"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/logo-red.png"
                    />
                  </div>
                  <span class="paragraph_2"
                    >地址：{{basedata.adr}}<br />电话：{{basedata.tel}}&nbsp;&nbsp;&nbsp;&nbsp;<br />邮箱：{{basedata.email}}</span
                  >
                </div>
              </div>
              <div class="line_red"></div>
              <div class="box_5 flex-row justify-between">
                <span class="text_62">IPmotion.&nbsp;沪ICP备2020037860号</span>
                <img
                  class="image_50"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngab0578b39c1306b573362cb4c97fa0e7926202959bb03ddffc30c5d31cc43604"
                />
              </div>
            </div>
          </div>

          <div class="showppdiv">
            <div class="closesw"></div>
            <div class="swiper sc">
              <div class="swiper-wrapper">
                <template v-for="(item, index) in data">
                  <div
                    class="swiper-slide ss0"
                    v-if="item.url == ''"
                    :key="index"
                  >
                    <div class="ss ss0">
                      <div class="ssin">
                        <div class="scontent">
                          <div class="sstitle" v-html="item.pname"></div>

                          <div class="ppkinds flex-row justify-start">
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div class="ppkitem flex-col" :key="index2">
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>

                          <div class="ppcontent" v-html="item.content"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>

          <!-- <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer> -->
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { fetchClass, fetchCase, fetchFriend, fetchBase } from "../api/index";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel,Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      basedata:[],
      frienddata: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [
        { name: "展览展厅&活动", id: "9" },
        { name: "数字营销&视频创作", id: "9" },
        { name: "智能平台&多媒体互动", id: "9" },
        { name: "奢侈品营销", id: "9" },
      ],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        { title: "公司简介", url: "", class: "jianjie" },
        { title: "客户伙伴", url: "", class: "huoban" },
        { title: "服务内容", url: "", class: "fuwuneirong" },
        { title: "项目案例", url: "", class: "anli" },
        { title: "联系我们", url: "", class: "lianxiwomen" },
        { title: "智能产品平台", url: "ai", class: "" },
      ],
      sectionindex: 0,
      sections: [
        {
          img: "i-logo-0.png",
          title: "展览展厅&amp;活动",
          desc: "提供前沿的创意理念，及线下制作的一体化服务",
          tip: ["临时展厅", "常设展厅", "多媒体互动", "交互内容", "线下活动"],
        },
        {
          img: "i-logo-1.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-2.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-3.png",
          title: "奢侈品营销",
          desc: "结合线下制作、运营于一体的互动体验",
          tip: ["促销活动", "体验营销"],
        },
      ],
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class2: [],
      },
    };
  },
  mounted() {
    let that = this;
    this.handleScroll;
    that.resizepage();
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);
    this.myswiper();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
    }, 1000);
    that.getClassList();
    that.fetch();
    that.fetchf();
    that.fetchb();
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    fetch(params = { pagesize: this.pageSize, query: this.query }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        console.log("data:", that.data);
        setTimeout(() => {
          that.swiper.update();
        }, 100);
      });
    },
    fetchf(params = { pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        console.log("data:", that.frienddata);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      console.log(id);
      this.query = {
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        console.log(
          "%cfetchClass",
          "color: green;font-size: 14px;font-weight: bold;",
          res
        );
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          mkinds.push({ name: item.cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    swSlideTo(index) {
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },
    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".swiper", {
          pagination: {
            // el: '.swiper-pagination',
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            transitionEnd: function () {
              that.selectIndex = this.activeIndex;
              console.log(that.selectIndex);
            },
            init: function (swiper) {
              this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      if (window.innerWidth > 1920) {
        this.pageWidth = 1920;
      }
      console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);
    },
    handleScroll(e) {
      const winHeight =
        e.target.scrollTop || document.documentElement.scrollTop;
      // console.log(winHeight);
      if (winHeight > 400) {
        this.topbarOpacity = (winHeight - 400) / 200;
        if (this.topbarOpacity > 1) {
          this.topbarOpacity = 1;
        }
      } else {
        this.topbarOpacity = 0;
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";

      if (url != "") {
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/index.css" />
<style src="../assets/css/index1280.css" />
<style src="../assets/css/index750.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
